export const keys = {
  bcryptEncryptAPI: 'z5getdqpSA4BdiyaBfAzL1FRN1Qyvubh6SqNCezk',
  bcryptDecryptAPI: 'saioBcFfXq53cCFXW54sc2Y0T0xxJvWt1NWlkPRI',
  emailLambdaAPI: 'pUcA6l1xO945Ve8NH9KUj11jJRuqWSLe9P1UHef0',
  dynamoAPI: 'iucP7c1O9X6HrHfKczyBw47TKOLcZelJ6cFvXsNs',
  lambdaRecaptchaAPI: 'wmqRK4VoNi7p3ZI9tvuUK9920Vk9kOum3C5HV0mM',
  lambdaGoogleCalendarAuthAPI: 'ZVZ90iaER87a60AkRgtyia8UKzvQn1Nb1YJkH0GU',
  lambdaGoogleCalendarGetEventsAPI: 'Ndz71Up2GI1lOjqYKNp492ASzIWKYaUC2HYXCko5',
  userTableName: 'community_user',
  webTableName: 'community_web',
  google: {
    recaptchaSiteKey: '6LcFUtslAAAAAJWjrmyYWkFwJ_546ENWiUN8EfJO',
    recaptchaSecretKey: '6LcFUtslAAAAADFzPZqf1TZsd8oo_DI-5tUoiK1I',
    calendarAPIkey: 'AIzaSyCKkuSx83BPCw6sBZ7JR7dPzoZoAWqWKWk',
    calendarId: 'miccosukeecommunity@gmail.com',
    calendarServiceAccountKey: {
      type: 'service_account',
      project_id: 'community-website-384917',
      private_key_id: 'fb69dd41a0132e4dd2e8d3ad0a1da86c446a828c',
      private_key:
        '-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCxRlCgbq674+al\npW6pbD4JAMCgWY6SnDzDPmlR3PejAXg6rjK5xqxMRfNNZV+O1QUy3vy/NtDnIbmj\nnzAYz0tHsMHngvg1XgY9Ehn6wu/uWMbxZWyhajby6XgYhYiiv9PdBCwOoDj9fNA8\ngYHh2UmnkQtCi5bGG1jMMz4HesbX0DD9FZ4vf+I4D6fRY1cgPmj9KbJST5WqN3yE\nu8HntweDTbnBLxTWAUOZ5tDSSjF4F3S1Fp9NctABlxbULSXOfTBK4/6rpl2hKujv\nFupnxXRSbqNCqrmlFW0ughE+dbs3SODzo1E7NWbpECyOlzGDCZltLtykOPYAXUlt\nUUy/lNDZAgMBAAECggEALfcedsrcMcnBB1hi3jk7yJfSWoF+L1OlibaIFnrmFvUN\nmKGMc5EpIcnNcVcEtQNVCsC45levSQ78lIl1LimpylfxnzbdZvEPSwyDgfrDvZh1\nVShIR35D6y5QPoN5T0HQDHHFfrFatZeEYvvtkoMFzxYTxlhtasayepga0FOsdU6O\nei049QTwsoKxVjZOqlLdCBpQA8/N1EdCFzk4ShTAu5bXUrAxOuxwZW3NCA6L+9vy\nLeszDn8cPu+b/QeLxfX8jXb8B2p6OHaI/WshECb/ab2NPMGxhtH+BGJQvKqmr2Uu\nb7EU/vYth01ls1UGe79nywCQdU+VTdn57EFyNVfTZQKBgQDkCIf/pGBR24sJ4qHA\nYr9reh2SQ7l2rHOyF4VftfqYBpRNKbVqRErdMYzhviz5UTsFRRr11DedHWmCJBoZ\nk5SsD58MXrUxlA//nBxFxjft6yEUkiWIRXF2dBzAt0NhhlmoCJvrr455jtkhwA3d\ncTyqc0OGU5u3Tan+mfPLhT648wKBgQDHBCKM1qF5Ufd1vZbdwycM5NBvkX05Vrj0\nEvu6Sj8JegyVnvCTZb4folVoTxmeQaXS8ag3mgKeUb+ILJgHnNHKbYDK2rJEGIsM\ndNvIXG7+qBFrCoE2IFwLuRw3nF9MkZ3EraGP0Twb1fmurFPw0xYkMeFnAkKqKlez\nSHXMNa5CAwKBgHo5+GuWd+poewY5a+nrBB9fvSoBExABL6Jt6Rd8O23xWNxjVtI2\n+MnHHXSCsyiP2crhV6ocrhKRCVSwdSzwZeg0F1RSD10bt9eSnbToomHsfjbAf02j\nq0WKxz6k/IDxumzO8IRL/Vo6rzff8Y6Ye6QBVFhTyEtgXZYSfxK/wPbxAoGAZFnL\naiTXqysPkokUx6Gd0TyXdYpxEDaQcFIaKGdDve2S5ucL++bzpVPfqQcprckAaot7\n/P1lkJs77tg9mKkUh/QLWz5HYVrW0mMiZ7dkKHhqwAS9/qL2htrCRbBNxXgvi3rZ\n2oDwxEBy9AblFKv7oCietahIITHUowxIib4Pri0CgYEA3hU9+LkpZJAx92ZI8vnK\nsERxjm+9kvf3Byp+2+aBFddyAoGdWEDXMt1fUSz3mF876Fq5NkgWsaINc5Nm3EU+\nfFgliKalB22R7z4QfxWu4ZlScP060Xxtche6Kog3LVzauN+PuoirKq/TON7WNImo\no9KUoSiL09Xyx+ITC1DNuNo=\n-----END PRIVATE KEY-----\n',
      client_email:
        'micco-community-calendar@community-website-384917.iam.gserviceaccount.com',
      client_id: '105194366461373483844',
      auth_uri: 'https://accounts.google.com/o/oauth2/auth',
      token_uri: 'https://oauth2.googleapis.com/token',
      auth_provider_x509_cert_url: 'https://www.googleapis.com/oauth2/v1/certs',
      client_x509_cert_url:
        'https://www.googleapis.com/robot/v1/metadata/x509/micco-community-calendar%40community-website-384917.iam.gserviceaccount.com',
      universe_domain: 'googleapis.com',
    },
  },
};
